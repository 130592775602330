import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout3 from '../components/layout2';
import Styles from "./mdlist.module.css";



const HomePage = (props: any) => {
  const { data } = props;


  return (
        <div style={{backgroundImage:"url('https://source.unsplash.com/featured/2000x2000/?globe')",height:"1000px",width:"100%"}}>
        <div style={{padding:"10%"}}>

          <h1 style={{fontSize:"4rem",color:"white", textShadow:"20px"}}>Social Area Analyze System</h1>
          <p style={{fontSize:"3rem",color:"white"}}>　　by 帝国書院</p>

<br/>
<br/>
<br/>
      <div style={{backgroundColor:"darkblue", color:"white", width:"30%", height:"3rem", fontSize:"2rem", textAlign:"center",margin:"1rem"}}>
      <Link to="/themeset" style={{color:"white", width:"8rem", height:"3rem", fontSize:"2rem",margin:"0.5rem"}}>SAASを使う</Link>
      </div>

      </div>
      </div>
  );
};

export default HomePage;

